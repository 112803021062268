import { faCalendarPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Checkbox, Colors, IconButton, OutlinedDropdown, Text } from 'library';
import React, { useEffect, useState } from 'react';
import { LocationTaskList, TaskListItem, TaskListSearchFilters } from './matchingInboxModels';
import { getTaskList } from './matchingInboxApi';
import { Grid, TablePagination } from '@mui/material';
import { PagingResult } from 'models';
import { GroupedTasks } from './components';
import TaskForm, { TaskFormOptions } from './TaskForm';
import { getTaskFormOptions } from './TaskFormFields';
import { AdminBusinessLocationOption } from 'models/AdminBusinessLocation';

export default function TaskList({
    isOpen,
    businessLocation,
    addTask = false,
}: {
    isOpen: boolean;
    businessLocation?: AdminBusinessLocationOption;
    addTask?: boolean;
}) {
    const [locationTasks, setLocationTasks] = useState<PagingResult<LocationTaskList>>();
    const [searchFilters, setSearchFilters] = useState<TaskListSearchFilters>({
        page: 1,
        inbox: '',
        page_size: 5,
        business_location: businessLocation?.id,
        include_resolved: false,
    });
    const [isAdding, setIsAdding] = useState(addTask);
    const [options, setOptions] = useState<TaskFormOptions>({
        inboxes: [],
        types: [],
        default_inbox: null,
        businessLocation: businessLocation,
    });
    const [editItem, setEditItem] = useState<TaskListItem>();

    useEffect(() => {
        getTaskFormOptions().then((result) => {
            setSearchFilters({
                ...searchFilters,
                inbox: result.default_inbox?.key || '',
            });
            setOptions({ ...result, businessLocation: businessLocation });
        });
    }, []);

    useEffect(() => {
        if (isOpen) {
            getTaskList({ ...searchFilters, business_location: businessLocation?.id }).then(setLocationTasks);
            if (businessLocation?.id !== options.businessLocation?.id) {
                setOptions({ ...options, businessLocation: businessLocation });
            }
        }
    }, [isOpen, searchFilters, businessLocation]);

    function refresh() {
        getTaskList({ ...searchFilters, business_location: businessLocation?.id }).then(setLocationTasks);
    }

    function onEdit(item: TaskListItem) {
        setEditItem(item);
    }

    function onResolve(item: TaskListItem) {
        const updated = locationTasks?.results.map((loc) => {
            const items = searchFilters.include_resolved
                ? loc.inbox_items.map((it) => (it.id === item.id ? { ...it, resolved: true } : it))
                : loc.inbox_items.filter((it) => it.id !== item.id);
            return { ...loc, inbox_items: items };
        });
        setLocationTasks((prev) => (prev && updated ? { ...prev, results: updated } : prev));
    }

    function onShowCompletedChange(val: boolean) {
        setSearchFilters((prev) => {
            return {
                ...prev,
                include_resolved: val,
            };
        });
    }

    return (
        <Grid justifyContent="space-between">
            {isAdding || Boolean(editItem) ? (
                <TaskForm
                    options={options}
                    onClose={() => {
                        setIsAdding(false);
                        setEditItem(undefined);
                        refresh();
                    }}
                    inbox={options.inboxes.find((i) => i.key === searchFilters.inbox)}
                    editItem={editItem}
                />
            ) : (
                <>
                    <Grid>
                        <Grid container justifyContent="space-between">
                            <OutlinedDropdown
                                label="Assigned to"
                                onChange={(e) => setSearchFilters({ ...searchFilters, inbox: e.target.value })}
                                value={searchFilters.inbox}
                                fields={options.inboxes}
                                style={{ width: 200 }}
                            />
                            <Checkbox
                                label="Show Completed"
                                checked={searchFilters.include_resolved || false}
                                onChange={onShowCompletedChange}
                                style={{ width: undefined }}
                            />
                            <IconButton
                                tooltip="Refresh"
                                onClick={refresh}
                                FontAwesomeImg={faRefresh}
                                color={Colors.white}
                                backgroundColor={Colors.mediumNavy}
                                iconStyle={styles.icon}
                                style={styles.iconButton}
                            />
                            <IconButton
                                tooltip="Add Task"
                                onClick={() => setIsAdding(!isAdding)}
                                FontAwesomeImg={faCalendarPlus}
                                color={Colors.white}
                                backgroundColor={Colors.mediumNavy}
                                iconStyle={styles.icon}
                                style={styles.iconButton}
                            />
                        </Grid>
                        {locationTasks && locationTasks?.results.length > 0 ? (
                            locationTasks?.results.map((location) => (
                                <GroupedTasks
                                    key={location.id}
                                    locationTaskList={location}
                                    refresh={refresh}
                                    onEdit={onEdit}
                                    onResolve={onResolve}
                                />
                            ))
                        ) : (
                            <Text>You have finished everything! 💪</Text>
                        )}
                    </Grid>
                    <TablePagination
                        component="div"
                        count={locationTasks?.count || 0}
                        rowsPerPage={locationTasks?.per_page || 0}
                        page={searchFilters.page - 1}
                        onPageChange={(_, page) => setSearchFilters({ ...searchFilters, page: page + 1 })}
                        rowsPerPageOptions={[]}
                    />
                </>
            )}
        </Grid>
    );
}

const styles = {
    iconButton: {
        marginBottom: 20,
    },
    icon: {
        marginTop: 5,
    },
};
