import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { BasicDialog, Colors, TextButton } from 'library';
import { client, queryStringifyObject } from 'shared';
import { useUserContext } from 'UserContext';

import Error from '../../../../parent-portal/provider-reviews/components/Error';
import { TextContainer } from '../../../../parent-portal/provider-reviews/components/Containers';
import { Header, InnerModalContent, BannedWorkerModalContext } from './components';

import type { Business, BusinessLocationBannedWorker, ShiftProviderUser } from 'models';

export default function BanWorkerModal({
    open,
    onSuccess,
    onClose,
    provider,
    businessLocationId,
    leaveFeedback,
}: {
    open: boolean;
    onSuccess: (bannedWorkers: BusinessLocationBannedWorker[], prompt: boolean) => void;
    onClose: () => void;
    provider: ShiftProviderUser;
    businessLocationId?: number;
    leaveFeedback?: boolean;
}) {
    const { user } = useUserContext();
    const history = useHistory();

    const [businessLocation, setBusinessLocation] = useState<{ id: number; name: string; business: { id: number } }>();
    const [business, setBusiness] = useState<Business | undefined>(user?.businesses_active?.[0]);
    const [locations, setLocations] = useState<BusinessLocationBannedWorker[]>([]);
    const [empty, setEmpty] = useState(false);
    const [error, setError] = useState(false);

    const businessIdNumber: number | undefined = business?.id ?? businessLocation?.business?.id;

    useEffect(() => {
        if (open) {
            if (businessIdNumber && provider.id) {
                client(
                    `api/business-outlaws/locations-and-statuses?business_id=${businessIdNumber}&business_outlaw_id=${provider.id}`,
                    {
                        method: 'GET',
                    },
                )
                    .then((response: BusinessLocationBannedWorker[]) => {
                        setLocations(response);
                        response?.length === 0 ? setEmpty(true) : setBusiness(response[0].business);
                        setError(false);
                    })
                    .catch(() => setError(true));
            } else {
                setError(true);
            }
        }
    }, [businessIdNumber, provider.id, open]);

    useEffect(() => {
        if (open && businessLocationId) {
            client(`api/business-location-overview/${businessLocationId}/`, { method: 'GET' })
                .then(setBusinessLocation)
                .catch(() => setError(true));
        }
    }, [businessLocationId, open]);

    function onBanSuccess(bannedWorkers: BusinessLocationBannedWorker[]) {
        if (user?.admin) {
            onSuccess(bannedWorkers, false);
        } else {
            const reviewParams = queryStringifyObject({
                business_id: businessIdNumber,
                user_id: provider.id,
                sentiment: 'block',
            });

            client(`api/provider-review/prompt-for-review/?${reviewParams}`)
                .then((response: { prompt: boolean }) => {
                    onSuccess(bannedWorkers, response.prompt);
                })
                .catch(() => {
                    onSuccess(bannedWorkers, false);
                });
        }
    }

    return (
        <BasicDialog
            isOpen={open}
            onClose={() => {
                setError(false);
                onClose();
            }}
            dialogTitle={error ? '' : `Request not to book ${provider.first_name}`}
            closeButton
        >
            {!user || !businessIdNumber || error ? (
                <>
                    <Error />
                    <TextContainer>
                        <Link
                            to="/home"
                            style={{ color: Colors.darkNavy, textDecoration: 'underline', fontWeight: 'bold' }}
                        >
                            Back home
                        </Link>
                    </TextContainer>
                </>
            ) : (
                <BannedWorkerModalContext.Provider
                    value={{
                        isAdmin: user?.admin ?? false,
                        business: business ?? {
                            id: businessIdNumber,
                            name: businessLocation?.name ?? `Business (${businessIdNumber})`,
                        },
                        provider,
                        emptyLocationList: empty,
                        locations,
                        businessLocationId,
                        setLocations,
                        setError,
                        onSuccess: onBanSuccess,
                        onClose,
                    }}
                >
                    <Header />
                    <InnerModalContent />
                    {leaveFeedback && (
                        <TextButton
                            onClick={() => history.push(`/providers/review/${provider.id}`)}
                            buttonStyle={{ marginTop: 20 }}
                        >
                            Leave feedback about {user.first_name}
                        </TextButton>
                    )}
                </BannedWorkerModalContext.Provider>
            )}
        </BasicDialog>
    );
}
