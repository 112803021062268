import { IInvoice } from 'internal/payments/Models';
import { PagingResult } from 'models';
import { client, queryStringifyObject } from 'shared';

export function search(
    page: number,
    name: string | null,
    date: string | null,
    status: string | null,
    overdue: boolean,
    businessLocationId: string | number | null,
): Promise<PagingResult<IInvoice>> {
    const qs = queryStringifyObject({ page, name, date, status, location: businessLocationId, overdue });
    const url = !qs ? 'payment/api/invoice/search/' : `payment/api/invoice/search/?${qs}`;

    return client(url);
}

export function getAdminInvoice(id: string | number): Promise<IInvoice> {
    return client(`payment/api/invoice/${id}/`);
}
