import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import DefaultProfileImage from 'assets/icons/Profile.svg';
import { client, consoleLogInDev } from 'shared';
import { PrimaryButton, Text, TextArea, Colors, LoadingIndicator, OutlinedDropdown } from 'library';
import theme from '../../theme';
import { PageStates } from 'constants/PageStates';
import { IProvider, IProviderReviewAnswer, ILoadResponse, BusinessLocation } from 'models';
import HighStarProviderReview from './HighStarProviderReview';
import LowStarProviderReview from './LowStarProviderReview';
import { Container } from '@mui/material';

interface ReviewProviderParams {
    id: string;
}

const ReviewProvider = ({ promptedByAction, userId }: { promptedByAction?: boolean; userId?: number }) => {
    const { id: uId } = useParams<ReviewProviderParams>();
    const [pageState, setPageState] = useState(PageStates.neutral);
    const [provider, setProvider] = useState<IProvider>();
    const [reviewOptions, setReviewOptions] = useState<IProviderReviewAnswer[]>([]);
    const [businessLocations, setBusinessLocations] = useState<BusinessLocation[]>([]);
    const [selectedCenter, setSelectedCenter] = useState<number>();
    const [totalScore, setTotalScore] = useState<number | undefined>(undefined);
    const [additionalFeedback, setAdditionalFeedback] = useState<string>();
    const [errorMessage, setErrorMessage] = useState('');

    const isPositive = (val: number | undefined) => val && val > 2;
    const isNegative = (val: number | undefined) => val && val <= 2;

    const isScorePositive = isPositive(totalScore);

    useEffect(() => {
        setPageState(PageStates.loading);
        const timeLimitParam = promptedByAction ? `&no_time_limit=${promptedByAction}` : '';
        client(`api/provider-review/get-review-info/?uid=${userId ?? uId}${timeLimitParam}`)
            .then((response: ILoadResponse) => {
                setProvider(response.provider);
                setReviewOptions(response.options as IProviderReviewAnswer[]);
                setBusinessLocations(response.locations);
                if (response.locations) {
                    setSelectedCenter(response.locations[0].id);
                }
                if (response.completed && !promptedByAction) {
                    setPageState(PageStates.submitted);
                } else {
                    setPageState(PageStates.neutral);
                }
            })
            .catch((e) => {
                consoleLogInDev(e);
                setPageState(PageStates.neutral);
            });
    }, []);

    const submitReview = () => {
        setPageState(PageStates.submitting);
        setErrorMessage('');

        client('api/provider-review/', {
            body: {
                reviewee: provider?.id,
                total_score: totalScore,
                text: additionalFeedback,
                review_results: reviewOptions?.filter((x) => x.value)?.map((x) => ({ option: x.id })),
                business_location: selectedCenter,
            },
        })
            .then(() => {
                setPageState(PageStates.submitted);
            })
            .catch((error) => {
                setErrorMessage(error.message || 'There was an error submitting your review.');
                setPageState(PageStates.neutral);
            });
    };

    const onOptionChange = (id: number, value: boolean) => {
        setReviewOptions(
            reviewOptions.map((existingOption) =>
                existingOption.id === id ? { ...existingOption, value } : existingOption,
            ),
        );
    };

    const updateTotalScore = (newScore?: number) => {
        const newScoreIsPositive = newScore && newScore > 2;
        if (!newScore || !totalScore || newScoreIsPositive !== isScorePositive) {
            setReviewOptions(reviewOptions.map((x) => ({ ...x, value: false })));
        }

        setTotalScore(newScore);
    };

    const isButtonEnabled = pageState === PageStates.neutral && totalScore;

    if (!provider) {
        return (
            <Container>
                <LoadingIndicator />
            </Container>
        );
    }

    if (pageState === PageStates.submitted && isPositive(totalScore) && !promptedByAction) {
        return <HighStarProviderReview provider={provider.user} />;
    }

    if (pageState === PageStates.submitted && isNegative(totalScore) && !promptedByAction) {
        return <LowStarProviderReview provider={provider.user} />;
    }

    if (pageState === PageStates.submitted && promptedByAction) {
        return (
            <Grid container item alignItems="center" xs={12} justify="center" style={{ paddingTop: '2%' }}>
                <Grid container item alignItems="center" sm={6} xs={12} justify="center">
                    <Grid item>
                        <Text textStyle={{ textAlign: 'center', padding: 10 }} variant="h1">
                            Review Submitted!
                        </Text>
                    </Grid>
                    <Grid container item xs={12} justify="center">
                        <Text textStyle={{ textAlign: 'center', padding: 20 }} variant="body1">
                            Thank you for taking the time to review{' '}
                            {provider?.user
                                ? `${provider.user.first_name} ${provider.user.last_name}`
                                : 'your provider'}
                            . We appreciate the feedback and will use this to better serve you and the rest of our
                            users.
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container direction="column" alignItems="center" style={{ paddingTop: '2%' }}>
            <Grid container direction="column" alignItems="center">
                <Text variant="h1">
                    Review {provider.user.first_name} {provider.user.last_name}
                </Text>
                {provider.user.profile_picture ? (
                    <img
                        alt="profile"
                        src={provider.user.profile_picture.image}
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            width: 150,
                            height: 150,
                            borderRadius: 100,
                            boxShadow: theme.shadows[1],
                        }}
                        id={(provider.id ?? -1).toString()}
                    />
                ) : (
                    <DefaultProfileImage
                        style={{ width: 150, height: 150, borderRadius: 15, boxShadow: theme.shadows[1] }}
                    />
                )}
            </Grid>
            <Grid>
                {businessLocations.length > 1 && selectedCenter && (
                    <OutlinedDropdown
                        value={selectedCenter || ''}
                        fields={businessLocations.map((center) => ({
                            key: center.id,
                            value: center.name,
                        }))}
                        onChange={(e) => setSelectedCenter(e.target.value)}
                    />
                )}
            </Grid>
            <Grid container direction="column" alignItems="center">
                <Grid item sm={6} xs={12} alignItems="center">
                    <Text bold textStyle={{ textAlign: 'center' }}>
                        {promptedByAction
                            ? `Would you like to leave a review of ${provider.user.first_name}?`
                            : `How was your shift with ${provider.user.first_name}?`}
                    </Text>
                </Grid>
                <Grid item sm={6} xs={12} alignItems="center">
                    <Rating
                        size="large"
                        value={totalScore || 0}
                        name="Overall"
                        onChange={(event, value) => updateTotalScore(value ?? undefined)}
                    />
                </Grid>
                {totalScore ? (
                    <>
                        <Grid item xs={12} alignItems="center" style={{ marginTop: '2%' }}>
                            <Text bold textStyle={{ textAlign: 'center' }}>
                                {isScorePositive ? 'What went well?' : 'What went wrong?'}
                            </Text>
                        </Grid>
                        <Grid container item sm={6} xs={12} alignItems="center" justify="center">
                            {reviewOptions
                                .filter((option) =>
                                    isScorePositive ? option.show_on_positive_reviews : option.show_on_negative_reviews,
                                )
                                ?.map((option) => (
                                    <Button
                                        key={option.id}
                                        disabled={!totalScore}
                                        disableRipple
                                        disableFocusRipple
                                        color="secondary"
                                        variant="contained"
                                        style={{
                                            margin: 10,
                                            backgroundColor: option.value
                                                ? isScorePositive
                                                    ? Colors.turquoise
                                                    : Colors.mediumRed
                                                : Colors.disabledGrey,
                                            textTransform: 'none',
                                        }}
                                        onClick={() => {
                                            onOptionChange(option.id, !option.value);
                                        }}
                                    >
                                        {option.text}
                                    </Button>
                                ))}
                        </Grid>
                    </>
                ) : (
                    <></>
                )}
                <Grid item sm={8} xs={12} style={{ marginTop: '.5%' }}>
                    <Text bold textStyle={{ textAlign: 'center' }}>
                        Additional Feedback
                    </Text>
                    <TextArea
                        value={additionalFeedback || ''}
                        onChange={(event) => setAdditionalFeedback(event.target.value)}
                        rowsMax={8}
                        style={{ height: 200, width: 350 }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PrimaryButton
                        onClick={submitReview}
                        disabled={!isButtonEnabled}
                        loading={pageState === PageStates.submitting}
                        buttonStyle={{ maxWidth: 200, marginTop: 20, marginBottom: 30 }}
                    >
                        Submit
                    </PrimaryButton>
                    <Text textStyle={{ color: Colors.error }}>{errorMessage}</Text>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ReviewProvider;
