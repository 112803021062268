import React, { useState, KeyboardEvent } from 'react';
import { consoleLogInDev } from 'shared';
import { Text, ErrorText, BasicDialog, OutlinedTextInput, PrimaryButton } from 'library';
import { verifyMfaCode } from 'api/SsnLast4Api';

export default function SsnMfaDialog({
    showMfaDialog,
    setShowMfaDialog,
    updateCookie
}: {
    showMfaDialog: boolean;
    setShowMfaDialog: React.Dispatch<React.SetStateAction<boolean>>;
    updateCookie: (token: string) => void;
}) {

    const [mfaCode, setMfaCode] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);

    const handleCloseMfaDialog = () => {
        setMfaCode('');
        setShowMfaDialog(false);
    };
    const onSubmitMfaCode = () => {
        if (mfaCode) {
            verifyMfaCode(mfaCode)
                .then((res) => {
                    updateCookie(res.one_day_token);
                    handleCloseMfaDialog();
                })
                .catch((e) => {
                    setShowError(true);
                    consoleLogInDev(e);
                });
        }
    };

    function onKeyDownInput(event: KeyboardEvent) {
        if (event.key === 'Enter' && mfaCode) {
            onSubmitMfaCode();
        }
    }

    return (
        <BasicDialog
            closeButton
            isOpen={showMfaDialog}
            dialogTitle={'ssn multi factor authentication'}
            onClose={handleCloseMfaDialog}
        >
            <Text>You should have received a text with a verification code. Enter it in the box to continue.</Text>
            <OutlinedTextInput
                fullWidth
                value={mfaCode}
                onChange={(e) => setMfaCode(e.target.value.toLocaleUpperCase())}
                InputLabelProps={{ shrink: true }}
                style={styles.mfaTextInput}
                onKeyDown={(event: KeyboardEvent) => onKeyDownInput(event)}
            />
            {showError && <ErrorText>Invalid code. Please try again.</ErrorText>}
            <PrimaryButton
                disabled={!mfaCode}
                onClick={onSubmitMfaCode}
                buttonStyle={styles.mfaSubmitButton}
                type="submit"
            >
                Submit
            </PrimaryButton>
        </BasicDialog>
    );
}

const styles = {
    mfaTextInput: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    mfaSubmitButton: {
        margin: 5,
    },
};
