import React, { useEffect } from 'react';
import { useBusinessLocationOverview } from '../api';
import { LoadingSpinner, Text, Colors, IconButton, Chip } from 'library';
import { Grid, styled, Divider } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { Notifications } from '@mui/icons-material';
import { consoleLogInDev, formatAddress } from 'shared';
import { formatMonthDayYearWith12HourTime } from 'shared/Dates';
import BusinessContact from './components/BusinessContact';
import LocationHistoryDisplay from './components/LocationHistoryDisplay';
import ViewAllBusinessLocationHistory from './components/ViewAllBusinessLocationHistory';
import ViewAllBusinessLocationStatusHistory from './components/ViewAllBusinessLocationStatusHistory';
import LocationHistoryHeader from './components/LocationHistoryHeader';
import LocationSummary from './components/LocationSummary';
import StatusChip from 'internal/business-locations/StatusChip';
import { refreshStatus, saveSnooze } from 'api/AdminBusinessLocationApi';
import ImpersonateButton from 'internal/impersonate/ImpersonateButton';
import EmailButton from '../BusinessLocationOverview/components/EmailButton';
import Label from 'internal/business-locations/Label';
import SnoozeButton from './components/SnoozeButton';

export default function BusinessLocationOverview({ businessLocationId }: { businessLocationId: number }) {
    const { status, error, data: businessLocation, refresh } = useBusinessLocationOverview(businessLocationId);
    const [selectedHistoryTypes, setSelectedHistoryTypes] = React.useState<string[]>(['All']);

    useEffect(() => {
        refresh(0, !selectedHistoryTypes.length ? undefined : selectedHistoryTypes);
    }, [selectedHistoryTypes]);

    function onRefreshClick() {
        refreshStatus(businessLocationId)
            .then(() => refresh())
            .catch(consoleLogInDev);
    }

    function unSoonzeClick() {
        if (confirm('Un-snooze this location?')) {
            saveSnooze(businessLocationId, null, null, null)
                .then(() => {
                    refresh();
                })
                .catch(consoleLogInDev);
        }
    }

    if (status === 'loading') return <LoadingSpinner />;
    if (status === 'error') return <Text>{error}</Text>;
    if (!businessLocation) return <Text>Location not found.</Text>;

    const pinnedNotes = businessLocation.notes.slice(0, 3);
    const emails = [
        businessLocation.business.user.email,
        ...businessLocation.business_contacts.map((c) => c.user.email),
    ];

    return (
        <Container container item gap={1}>
            <Grid container item>
                <Grid item container xs={12} md={4} direction="column">
                    <Text variant="h1">{businessLocation.name} Overview</Text>
                    <Text variant="caption">{formatAddress(businessLocation.address, false)}</Text>

                    {businessLocation.snooze_until && (
                        <Text variant="caption">
                            <b>Snoozed Until: </b>
                            {formatMonthDayYearWith12HourTime(businessLocation.snooze_until)}
                            <IconButton
                                tooltip="Unsnooze center"
                                style={{ margin: '8px 5px' }}
                                icon={Notifications}
                                onClick={unSoonzeClick}
                            />
                        </Text>
                    )}
                    <Grid item container direction="row" gap={1} alignItems={'center'}>
                        <ImpersonateButton email={businessLocation.business.user.email} />
                        <EmailButton contacts={emails} />
                        <SnoozeButton
                            businessLocationId={businessLocationId}
                            snoozeUntil={businessLocation.snooze_until}
                            snoozeReason={businessLocation.snooze_reason}
                            note={businessLocation.snooze_note_text}
                            onSnooze={() => refresh(0, undefined)}
                        />
                        <ViewAllBusinessLocationStatusHistory businessLocation={businessLocation} />
                    </Grid>
                </Grid>
                <Grid item container direction="column" xs={12} md={8}>
                    <div>
                        <Label label="Cust. Status" />
                        <StatusChip text={businessLocation.status || 'Unknown'} />
                        <IconButton
                            tooltip="Refresh location status"
                            style={{ marginLeft: 5 }}
                            icon={Refresh}
                            onClick={onRefreshClick}
                        ></IconButton>
                    </div>
                    <StatusContainer>
                        <Label label="Job Status" />
                        {businessLocation.has_job_current_week && (
                            <StatusChip text={'Jobs This Week'} color={Colors.turquoise} />
                        )}
                        {businessLocation.has_job_next_week && (
                            <StatusChip text={'Jobs Next Week'} color={Colors.darkNavy} />
                        )}
                    </StatusContainer>
                    {businessLocation.autobook_paused_until &&
                    new Date(businessLocation.autobook_paused_until) > new Date() ? (
                        <Text variant="caption">
                            Autobook paused until:{' '}
                            {formatMonthDayYearWith12HourTime(businessLocation.autobook_paused_until)}
                        </Text>
                    ) : null}
                    {pinnedNotes.length ? <Text>Notes:</Text> : null}
                    <div style={{ maxHeight: 125, overflowY: 'scroll' }}>
                        {pinnedNotes.map((x) => (
                            <Text key={x.id} variant="body2" textStyle={{ margin: '5px 0' }}>
                                {x.pinned && (
                                    <Chip
                                        label="Pinned"
                                        backgroundColor={Colors.turquoise}
                                        textColor={Colors.white}
                                        style={{ marginRight: 5 }}
                                    />
                                )}
                                {x.is_from_call && (
                                    <Chip
                                        label="Call"
                                        backgroundColor={Colors.pink}
                                        textColor={Colors.white}
                                        style={{ marginRight: 5 }}
                                    />
                                )}
                                <b>
                                    {formatMonthDayYearWith12HourTime(x.added_at)} ({x.author.first_name}{' '}
                                    {x.author.last_name}):
                                </b>{' '}
                                {x.text}
                            </Text>
                        ))}
                    </div>
                </Grid>
            </Grid>
            <Grid item container gap={1} direction="column" style={{ marginTop: 5 }}>
                <Text bold>Business Contacts:</Text>
                <Grid item container direction="row" gap={1}>
                    {businessLocation.business_contacts.map((c) => (
                        <BusinessContact
                            key={c.id}
                            userId={c.user.id}
                            firstName={c.user.first_name}
                            lastName={c.user.last_name}
                            phoneNumber={c.user.phone_number}
                            email={c.user.email}
                            communicationPreference={c.user.communication_preference}
                            refresh={refresh}
                        />
                    ))}
                    <BusinessContact
                        userId={businessLocation.business.user.id}
                        firstName={businessLocation.business.user.first_name}
                        lastName={businessLocation.business.user.last_name}
                        phoneNumber={businessLocation.business.user.phone_number}
                        email={businessLocation.business.user.email}
                        communicationPreference={businessLocation.business.user.communication_preference || ''}
                        backgroundColor={Colors.lightRed}
                        refresh={refresh}
                    />
                </Grid>
                <Divider style={{ marginBottom: 20 }} />
            </Grid>
            <LocationSummary businessLocationId={businessLocation.id} />
            <Grid item container gap={1} direction="column">
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <LocationHistoryHeader setSelectedTypes={setSelectedHistoryTypes} />
                <LocationHistoryDisplay history={businessLocation.history} />
                <ViewAllBusinessLocationHistory businessLocation={businessLocation} />
            </Grid>
        </Container>
    );
}

const Container = styled(Grid)({
    padding: 20,
});

const StatusContainer = styled('div')({
    paddingBottom: 10,
    paddingTop: 10,
});
