import React, { useState } from 'react';
import { ValidationError, ErrorArray, FormData, DocumentToVerify } from './ILFingerprintCheckTypes';
import { AdminProvider } from 'models';
import { BasicDialog, PrimaryButton, ErrorText } from 'library';
import { Grid } from '@mui/material';

import { consoleLogInDev } from 'shared';
import ILFingerprintCheckFormField from './ILFingerprintCheckFormField';
import ILFingerprintCheckResultsPaper from './ILFingerprintCheckResultsPaper';
import SsnMfaDialog from './SsnMfaDialog';

import moment from 'moment';
import * as Yup from 'yup';
import { getWorkerSsnLast4, triggerMfaCode } from 'api/SsnLast4Api';
import { recheckDCFSFingerprintSearch } from 'api/DCFSFingerprintSearchApi';
import { useCookies } from 'react-cookie';
import { useUserContext } from 'UserContext';
import { addDays } from 'date-fns';

const formSchema = Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    date_of_birth: Yup.string()
        .required()
        .matches(/^\d{2}\/\d{2}\/\d{4}$/, 'date_of_birth must be in the format "XX/XX/XXXX"'),
    ssn_last_four: Yup.string()
        .length(4)
        .required()
        .matches(/^\d{4}$/, 'ssn_last_four must be in the format "XXXX"'),
});

export default function ILFingerprintCheckDialog({ provider }: { provider: AdminProvider }) {
    const initialValues: FormData = {
        first_name: provider.user.first_name,
        last_name: provider.user.last_name,
        date_of_birth: moment(provider.birthday).format('MM/DD/YYYY'),
        ssn_last_four: '',
    };

    const [cookies, setCookie] = useCookies();
    const { refreshUser } = useUserContext();
    const [showMfaDialog, setShowMfaDialog] = useState<boolean>(false);
    const [fingerprintCheckDocument, setFingerprintCheckDocument] = useState<DocumentToVerify | undefined>();
    const [fingerprintsOnFile, setFingerprintsOnFile] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [first_name, setFirstName] = useState<string>(initialValues.first_name);
    const [last_name, setLastName] = useState<string>(initialValues.last_name);
    const [date_of_birth, setDateOfBirth] = useState<string>(initialValues.date_of_birth);
    const [ssn_last_four, setSsnLast4] = useState<string>(initialValues.ssn_last_four);
    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ErrorArray>([]);
    const [errorState, setErrorState] = useState<Record<string, boolean>>({
        first_name: false,
        last_name: false,
        date_of_birth: false,
        ssn_last_four: false,
    });

    const dialogTitle = `Fingerprint Check Search for \n${provider.user.first_name} ${provider.user.last_name}`;

    async function handleFormValidation(data: FormData) {
        const isFormValid = await formSchema.isValid(data, {
            abortEarly: false, // NOTE: Prevent aborting validation after first error
        });
        if (isFormValid) {
            setValidationErrors([]);
            return true;
        } else {
            // NOTE: if form is not valid, check which fields are incorrect and add them to useState errors array
            formSchema.validate(data, { abortEarly: false }).catch((error) => {
                const tempErrors: ErrorArray = [];
                const tempErrorState = errorState;
                for (const yupError of error.inner) {
                    // NOTE: just use the first error for that input value
                    if (!tempErrors.find((error) => error.label === yupError.path)) {
                        tempErrors.push({
                            label: yupError.path,
                            message: yupError.message,
                        });
                        tempErrorState[yupError.path] = true;
                    }
                }
                setValidationErrors(tempErrors);
                setErrorState(tempErrorState);
                return false;
            });
        }
    }

    const handleSubmit = () => {
        setLoading(true);
        const data = {
            first_name,
            last_name,
            date_of_birth,
            ssn_last_four,
        };
        handleFormValidation(data)
            .then((formValid) => {
                if (formValid) {
                    setFingerprintsOnFile(false);
                    setFingerprintCheckDocument(undefined);
                    recheckDCFSFingerprintSearch(provider.id, data)
                        .then((res) => {
                            setFingerprintsOnFile(res.fingerprints_on_file);
                            if (res.fingerprints_on_file_document.id) {
                                setFingerprintCheckDocument(res.fingerprints_on_file_document);
                            } else {
                                throw Error('received non-standard response');
                            }
                        })
                        .catch((e) => {
                            consoleLogInDev(`error re-checking worker's bg check: ${e.message}`);
                            const tempErrors = validationErrors;
                            tempErrors.push({
                                label: 'form',
                                message: `form submission error - contact support`,
                            });
                            setValidationErrors(tempErrors);
                            setSubmitDisabled(true);
                        })
                        .finally(() => setLoading(false));
                }
                else {
                    setLoading(false);
                }
            })
            .catch((e) => {
                consoleLogInDev(e);
                setLoading(false);
            });
    };

    const handleCloseBgCheckDialog = () => {
        setValidationErrors([]);
        setFirstName(initialValues.first_name);
        setLastName(initialValues.last_name);
        setDateOfBirth(initialValues.date_of_birth);
        setSsnLast4(initialValues.ssn_last_four);
        setSubmitDisabled(false);
        refreshUser();
        setShowDialog(false);
    };

    const handleOpenBgCheckDialog = (one_day_token?:string) => {
        if (cookies._view_tok || one_day_token) {
            setShowDialog(true);
            getWorkerSsnLast4(provider.id, cookies._view_tok)
                .then((res) => {
                    if (res && res.length) {
                        setSsnLast4(res);
                    } else {
                        throw new Error(`error retrieving workers ssn last 4`);
                    }
                })
                .catch((e) => consoleLogInDev(e));
        } else {
            triggerMfaCode();
            setShowMfaDialog(true);
        }
    };

    const updateCookie = (one_day_token: string) => {
        const oneDayInFuture = addDays(new Date(), 1);
        setCookie('_view_tok', one_day_token, {
            path: '/adminperson',
            expires: oneDayInFuture,
            secure: true,
            sameSite: 'strict',
        });
        handleOpenBgCheckDialog(one_day_token);
    };

    return (
        <>
            <PrimaryButton buttonStyle={styles.retryButton} onClick={() => handleOpenBgCheckDialog()}>
                Run Fingerprint Check
            </PrimaryButton>
            <BasicDialog closeButton isOpen={showDialog} onClose={handleCloseBgCheckDialog} dialogTitle={dialogTitle}>
                <ILFingerprintCheckFormField
                    fieldNameDisplay="First Name"
                    fieldNameValue={first_name}
                    setFieldNameValue={setFirstName}
                    isErrorState={errorState.first_name}
                />
                <ILFingerprintCheckFormField
                    fieldNameDisplay="Last Name"
                    fieldNameValue={last_name}
                    setFieldNameValue={setLastName}
                    isErrorState={errorState.last_name}
                />
                <ILFingerprintCheckFormField
                    fieldNameDisplay="Date of Birth"
                    fieldNameValue={date_of_birth}
                    setFieldNameValue={setDateOfBirth}
                    isErrorState={errorState.date_of_birth}
                />
                <ILFingerprintCheckFormField
                    fieldNameDisplay="SSN Last 4"
                    fieldNameValue={ssn_last_four}
                    setFieldNameValue={setSsnLast4}
                    isErrorState={errorState.ssn_last_four}
                />
                <Grid container direction="row" justifyContent="space-between" spacing={2}>
                    <Grid item xs={fingerprintCheckDocument ? 6 : 12}>
                        <PrimaryButton
                            buttonStyle={styles.bgCheckSubmitButton}
                            onClick={handleSubmit}
                            loading={loading}
                            disabled={submitDisabled}
                        >
                            Submit
                        </PrimaryButton>
                    </Grid>
                    <Grid item xs={fingerprintCheckDocument ? 6 : 0}>
                        {fingerprintCheckDocument && (
                            <PrimaryButton buttonStyle={styles.bgCheckSubmitButton} onClick={handleCloseBgCheckDialog}>
                                Close
                            </PrimaryButton>
                        )}
                    </Grid>
                </Grid>
                {validationErrors.map((error: ValidationError, index: number) => (
                    <div key={index} style={styles.errorMessage}>
                        <ErrorText>{error.message}</ErrorText>
                    </div>
                ))}
                {fingerprintCheckDocument && (
                    <ILFingerprintCheckResultsPaper
                        fingerprintsOnFile={fingerprintsOnFile}
                        fingerprintCheckDocument={fingerprintCheckDocument}
                    />
                )}
            </BasicDialog>
            <SsnMfaDialog
                showMfaDialog={showMfaDialog}
                setShowMfaDialog={setShowMfaDialog}
                updateCookie={updateCookie}
            />
        </>
    );
}

const styles = {
    retryButton: {
        width: 300,
        marginTop: 10,
        marginLeft: 10,
    },
    errorMessage: {
        marginTop: 10,
    },
    bgCheckSubmitButton: {
        marginTop: 20,
    },
};
