import { addDays, differenceInDays } from 'date-fns';

export function getExpirationInfo(requestRenewedAt?: string, requestPostedAt?: string) {
    const renewedAtString = requestRenewedAt || requestPostedAt;
    const renewedAt = renewedAtString ? new Date(renewedAtString) : null;
    const expiresAt = renewedAt ? addDays(renewedAt, 30) : null;
    const isExpired = expiresAt ? expiresAt < new Date() : false;
    const expiresInDays = expiresAt && !isExpired ? differenceInDays(expiresAt, new Date()) : null;

    return {
        expiresAt,
        isExpired,
        expiresInDays,
    };
}
