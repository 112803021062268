import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Text } from 'library';
import { GeneralDocumentVerifyProps } from '../models';
import { addYears, format } from 'date-fns';

export default function GeneralDocumentVerify({ setExpirationDate }: GeneralDocumentVerifyProps) {
    const today = format(new Date(), 'yyyy-MM-dd');
    const twoYearsFromNow = format(addYears(new Date(), 2), 'yyyy-MM-dd');
    useEffect(() => {
        setExpirationDate(new Date(twoYearsFromNow));
    }, []);
    const [selectedDate, setSelectedDate] = useState(twoYearsFromNow);
    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDate(event.target.value);
        setExpirationDate(new Date(event.target.value));
    };
    return (
        <Grid container item direction="column">
            <Text bold textStyle={{ margin: 5 }}>
                Before verifying, check the following...
            </Text>
            <Text textStyle={{ margin: 5 }}>✔ The document has correct providers name</Text>

            <Text bold textStyle={{ margin: 15 }}>
                Optional - Select Date Form Expires
            </Text>
            <input type="date" onChange={handleDateChange} min={today} value={selectedDate} />
        </Grid>
    );
}
