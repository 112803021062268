import { PagingResult } from 'models';
import { client, queryStringifyObject } from 'shared';
import { InboxCount, LocationTaskList, TaskListSearchFilters } from './matchingInboxModels';

export async function getTaskList(searchFilters: TaskListSearchFilters): Promise<PagingResult<LocationTaskList>> {
    const params = queryStringifyObject(searchFilters);
    return await client(`api/matching-inbox/location-tasks/?${params}`);
}

export async function getInboxCountSummary(regions: number[]): Promise<InboxCount[]> {
    return await client(`api/matching-inbox/count/?regions=${regions.join(',')}`);
}
