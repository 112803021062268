import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Text } from 'library';
import { MedicalRecordVerifyProps } from '../models';
import { format } from 'date-fns';

export default function MedicalRecordVerify({ setSignedAt }: MedicalRecordVerifyProps) {
    const stepsBeforeVerification = [
        '✔ All vaccination section boxes are checked',
        '✔ Has signature',
        '✔ TB Section has checkmarks for "no" if empty',
        '✔ Signed in the past year',
    ];
    const today = format(new Date(), 'yyyy-MM-dd');
    const [selectedDate, setSelectedDate] = useState(today);
    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDate(event.target.value);
        setSignedAt(new Date(event.target.value));
    };

    return (
        <Grid container item direction="column">
            <Text bold textStyle={{ margin: 5 }}>
                Before verifying, check the following...
            </Text>
            {stepsBeforeVerification.map((step, index) => (
                <Text key={index} textStyle={{ margin: 5 }}>
                    {step}
                </Text>
            ))}
            <Text bold textStyle={{ margin: 15 }}>
                Select Date Form Was Signed
            </Text>
            <input type="date" onChange={handleDateChange} max={today} value={selectedDate} />
        </Grid>
    );
}
